import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { mdiCardTextOutline } from '@mdi/js';
import TextArea from '../../../common/TextArea';
import { useForm } from '../../../FormContext';
import { Content, Header, Section } from './RightMenuSection';
import RiskTags from './RiskTags';
import RiskResponsibleUsers from './RiskResponsibleUsers';
import { useRights } from '../../../RightsContext';

function GeneralTab() {
  const intl = useIntl();
  const { state, dispatchFormChange } = useForm();
  const { hasModifierRight } = useRights();

  return (
    <>
      <Section>
        <Header
          label={intl.formatMessage({ id: 'DESCRIPTION' })}
          htmlFor="description-input"
          iconPath={mdiCardTextOutline}
        />
        <Content>
          <DescriptionField
            id="description-input"
            readOnly={!hasModifierRight()}
            placeholder={
              hasModifierRight()
                ? intl.formatMessage({ id: 'ADD_DESCRIPTION' })
                : null
            }
            value={state.description.value}
            onChange={(event) =>
              dispatchFormChange({
                field: 'description',
                value: event.target.value,
              })
            }
            aria-label="Risk description"
          ></DescriptionField>
        </Content>
      </Section>
      <RiskTags />
      <RiskResponsibleUsers />
    </>
  );
}

const DescriptionField = styled(TextArea)`
  height: 280px;
  border-color: transparent;
  background-color: ${(props) => props.theme.arter.color.extraLightGray};

  &:disabled {
    opacity: 1;
  }

  &:hover {
    background-color: ${(props) => props.theme.arter.color.veryLightGray};
    border-color: transparent;
  }

  &:active,
  &:focus,
  &:focus-visible {
    background-color: ${(props) => props.theme.arter.color.extraLightGray};
    border: 1px solid ${(props) => props.theme.arter.color.product.default};
  }
    
  &:read-only {
    cursor: default;

     &:active,
     &:focus,
     &:focus-visible {
       border: 1px solid transparent;
       box-shadow: none;
     }

     &:hover {
       background-color: ${(props) => props.theme.arter.color.extraLightGray};
     }
  }
`;

export default GeneralTab;
